<template>
  <div class="m-4">
    <h2>Features of Jukebox Tools</h2>
    <p>
      Explore the unique features that make Jukebox Tools your go-to platform
      for managing and enjoying your jukebox record collection.
    </p>

    <h3>Record Organization</h3>
    <p>
      Our robust record organization system allows you to efficiently catalog
      your entire collection. Features include:
    </p>
    <ul>
      <li>
        <strong>Easy Input:</strong>
        Quickly add records to your collection using our streamlined interface.
      </li>
      <li>
        <strong>Advanced Search:</strong>
        Find any record in seconds with our powerful search tool.
      </li>
      <li>
        <strong>Collection Insights:</strong>
        Get an overview of your collection with our detailed analytics and
        statistics.
      </li>
    </ul>
    <br />

    <h3>Custom Title Strips Creation</h3>
    <p>
      Personalize your jukebox with custom title strips, crafted using our
      user-friendly design tool. This feature offers:
    </p>
    <ul>
      <li>
        <strong>Template Library:</strong>
        Choose from a variety of templates to start your design.
      </li>
      <li>
        <strong>Customization Options:</strong>
        Add your personal touch with custom text, images, and colors.
      </li>
      <li>
        <strong>Print-Ready:</strong>
        Export your title strips in a print-ready format, perfect for any jukebox.
      </li>
    </ul>
    <br />

    <h3>Jukebox Layout Tool</h3>
    <p>
      We provivde a tool to help plan where you want your records to be placed in your jukebox; before you start pulling records
    </p>
    <ul>
      <li>
        <strong>Customize your layout:</strong>
        Configure how many labels your Jukebox has
      </li>
      <li>
        <strong>Customize your jukebox colors</strong>
        Match your layout to your jukebox and titlestrips
      </li>
      <li>
        <strong>Save and retrive layouts</strong>
        See what records you used to have in your jukebox
      </li>
    </ul>
    <br />

    <h3>Continuous Updates</h3>
    <p>
      We constantly improve Jukebox Tools, adding new features and enhancements
      regularly to ensure the best experience for our users.
    </p>

    <p>
      <strong>
        Discover the full potential of your jukebox with Jukebox Tools – where
        technology meets tradition.
      </strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "features",
};
</script>
